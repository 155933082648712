<template>
  <div class="bills-page">
    <van-empty v-if="!bills || bills.length < 1" description="暂无记录" />
    <van-pull-refresh v-model="pullLoading" @refresh="refresh">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="getNote"
        class="bill-list"
      >
        <dl v-for="bill in bills" :key="bill.biz_no" class="bill clearfix">
          <dt>提现金额</dt>
          <dd><strong>￥2999.00</strong></dd>
          <dt>账户</dt>
          <dd>{{ bill.identity | hideword }}</dd>
          <dt>用户名</dt>
          <dd>{{ bill.name | hideword }}</dd>
          <dt>提现时间</dt>
          <dd class="small">{{ bill.timestamp | formatDate }}</dd>
          <dd class="muted" :class="`status-${bill.status}`">
            {{ status[bill.status - 1] }}
          </dd>
        </dl>
      </van-list>
    </van-pull-refresh>
  </div>
</template>


<script>
export default {
  name: "Bills",
  data() {
    return {
      bills: null,
      page: 0,
      loading: false,
      finished: false,
      pullLoading: false,
      status: ["待提现", "处理中", "提现成功", "提现失败"],
    };
  },
  created() {},
  methods: {
    refresh() {
      this.page = 0;
      this.getNote();
    },
    getNote() {
      ;
      this.$ajax
        .post("/agent/withdraw/bills/", {

          page_no: this.page,
          page_size: 10,
        })
        .then((res) => {
          if (this.page === 0) {
            this.bills = res.data;
          } else {
            if (res.data) {
              this.bills = [...this.bills, ...res.data];
            }
          }
          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
          this.loading = false;
          this.pullLoading = false;
        });
    },
  },
};
</script>